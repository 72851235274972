/*
  Container for the bottom overlay
*/
.gc__overlay_bottom_container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  /* color: #f1f1f1; */
  width: 100%;
  padding: 20px;
}

/*
  The pills in the record state
*/
.gc__video_pill_container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.gc__video_pill {
  display: flex;
  justify-content: center;
  width: 170px;
  padding: 5px 25px;
  font-size: 1.5em;
  font-weight: bold;
  color: #ffffff;
}

.gc__video_pill_help_text {
  display: flex;
  justify-content: center;
  width: 170px;
  font-size: 0.8em;
  color: #ffffff;
}

/*
  The record / stop recording buttons and
  the circle / square used as the icons for the buttons
*/
.gc__stop_record_button {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%;
  border: none;
  height: 90px;
  width: 90px;
  cursor: pointer;
  background-color: #cb2121;
}

.gc__start_record_button {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%;
  border: none;
  height: 90px;
  width: 90px;
  cursor: pointer;
  background-color: white;
}

.gc__start_record_button:disabled {
  background: #ccc;
}

.gc__record_dot {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #cb2121;
  display: flex;
  margin: auto;
}

.gc__record_square {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  margin: auto;
}

/*
  The container for the playback buttons
*/
.gc__overlay_bottom_button_contianer {
  width: 100%;
  display: flex;
  justify-content: center;
}

/*
  The pause and play buttons
*/
.gc__circle_button {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%;
  border: none;
  height: 90px;
  width: 90px;
  cursor: pointer;
  background: none;
}

.gc__playback_button_svg {
  height: 40px;
  width: 40px;
}

/*
  The Save to card button
*/
.gc__save_thumbnail_button {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 25px;
  min-width: 150px;
}

@media only screen and (max-width: 450px) {
  .gc__video_pill_help_text {
    display: none;
  }

  .gc__select_thumbnail_message {
    padding: 6px 35px;
  }
}
