.gc__button_container {
  max-width: 100%;
  margin: 0.4em 0;
}

.gc__btn {
  margin: 0;
  outline: 0;
  padding: 20px;
  width: 100%;
  background: var(--gc__var-brand_color);
  color: var(--gc__var-button_text_color);
  font-size: 16px;
  font-weight: bold;
  line-height: 1em;
  text-transform: capitalize;
  border-width: 2px;
  border-style: none;
  border-image: none;
  border-radius: 0.3em;
}

.gc__btn img {
  width: 1em;
  height: 1em;
  margin-right: 8px;
  vertical-align: middle;
}

.gc__btn:hover {
  cursor: pointer;
}

.gc__btn.gc__subdued_btn {
  background: #fff;
  border: 1px solid var(--gc__var-accent_color);
  color: var(--gc__var-inactive_button_text_color);
  box-shadow: none;
  font-weight: normal;
}
.gc__btn.gc__subdued_btn:hover {
  background: var(--gc__var-accent_color);
  border: 1px solid var(--gc__var-accent_color);
  color: var(--gc__var-active_button_text_color);
}
.gc__btn.gc__borderless_btn {
  color: var(--gc__var-brand_color);
  background: transparent;
  border: none;
  cursor:pointer;
}
.gc__btn.gc__borderless_btn:hover {
  color: var(--gc__var-brand_color);
  background: transparent;
  border: none;
}

.gc__full_width_btn {
  width: 100%;
}

.gc__btn .gc__icon {
  height: 2em;
}



.gc__button_image {
  display: flex;
  margin: 0;
  outline: 0;
  padding: 20px;
  width: 100%;
  background: transparent;
  border: 1px dashed #8f8f8f;
  color: #404040;
  font-size: 16px;
  font-weight: bold;
  line-height: 1em;
  text-transform: capitalize;
  border-radius: 0.3em;
}

.gc__button_image:hover {
  cursor: pointer;
  background: #f9f9f9;
}

.gc__button_image:active {
  box-shadow: inset 2px 2px 3px 0px #00000021, inset 0px 0px 5px -1px rgba(0, 0, 0, 0.01);
}

.gc__button_image .gc__icon {
  height: 2em;
}

.gc__button_image_container {
  width: 55px;
  margin: 0.4em 0;
  max-width: 100%;
}

.gc__button_image_text {
  flex: 1;
  font-size: 16px;
  font-family: inherit;
  max-width: 100%;
  display: flex;
  align-items: center;
  height: 2em;
  margin: 0.4em 0;
}

.gc__media_button_container {
  display: flex;
  flex-wrap: wrap;
}

.gc__btn.gc__media_button {
  display: block;
  border: 2px dashed #bde5f3;
}
.gc__btn.gc__media_button:hover {
  display: block;
  background: #bde5f3;
  border: 2px solid #bde5f3;
}
.gc__btn.gc__media_button:hover .gc__icon {
  transform: scale(2, 3);
  -webkit-filter: invert(1) contrast(500%);
}

.gc__btn.gc__subdued_btn .gc__icon {
  opacity: 0.5;
}

.gc__btn:not(.gc__subdued_btn) .gc__icon {
  filter: invert(1);
}

.gc__thumbnail_upload_label {
  color: lightblue;
  font-size: inherit;
  cursor: pointer;
}

#gc__thumbnail_upload {
  display: none;
}
