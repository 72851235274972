[data-theme="simple"] {

  .gc__mega-radio-container {
    grid-template-columns: 1fr 1fr;
  }

  #gcpGreetingCardSection + .gc__section {

  }
  #gcpAnimatedCardWrapper {
    overflow: visible;
  }

  .gc__breadcrumbs,
  .gc__form_header.gc__section {
    display: none;
  }

  .gc__message-from-text {
    display: none;
  }

  .gc__preview-page {
    height: 100%;
  }

  .gc__container {
    border-radius: 0;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    --stripe: transparent;
    --bg: transparent;
  }

  .gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input:checked + label.gc__radio_label,
  .gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input:checked + label.gc__radio_label:hover,
  .gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input:checked + label.gc__radio_label:active {
    box-shadow: none;
  }
  .gc__container:nth-child(even) .gc__grid-item:nth-child(1),
  #gcpAnimatedCardBackround  {
    background: transparent;
  }

  .gc__layout_wrapper {

    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    overflow: auto;
    overflow: auto;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    max-width: 100%;
    border-radius: 0px;
    border: none;

    /** Form section */
    > div:first-of-type {
      border-right: 1px solid #eee;
      flex: 1 0 50%;
      .gc__step_active {
        min-height: 0px;
        padding-bottom: 0px;
      }
      .gc__giftcard_page .gc__greeting-card-section.gc__section {
        min-height: 0px;
      }
      .gc__step_inactive {
        pointer-events: auto;
        display: block;
        min-height: 0px;
        position: relative;
        width: 100%;
        opacity: 1;
        visibility: visible;
        -webkit-animation: fadeInFromNone 0.5s ease-out;
        -moz-animation: fadeInFromNone 0.5s ease-out;
        -o-animation: fadeInFromNone 0.5s ease-out;
        animation: fadeInFromNone 0.5s ease-out;
      }

      .gc__radio_buttons_container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
      }
      .gc__delivery_picker_div .gc__radio_buttons_container {
        grid-template-columns: 1fr 1fr;
        column-gap: 0rem;
        row-gap: 0rem;
      }
      .gc__tab_content_wrapper {
        display: flex;
        flex-wrap: nowrap;
      }

      .gc__giftcard_page {
        width: 100%;
        margin: auto;
        border: none;
        max-height: none;
        box-shadow: none;
        border-radius: none;
        padding: 2em 8em 2em 14em;
        form {
          position: relative;
        }
      }

      @media screen and (max-width: 1600px) {
        .gc__giftcard_page {
          padding: 2em 1em;
        }
      }

      @media screen and (max-width: 992px) {
        .gc__giftcard_page {
          width: 0;
        }
      }

      .gc__giftcard_page .gc__greeting-card-section.gc__section {
        height: auto;
      }

      .gc__giftcard_selection_div {
        height: auto !important;
        min-height: auto !important;
      }

      .gc__tab_content {
        flex: 1 0 100%;
        width: 100%;
        position: relative;
        display: none;
      }
      .gc__tab_content.gc__tab_content_active {
        display: block;
      }

    }

    > div:first-of-type::after {

    }

    /** Preview Section */
    .gc__giftcard_preview {
      position: sticky;
      width: 100%;
      background: #f9f9f9;
      max-height: 100vh;
      min-height: 100vh;
      margin: 0;
      top: 0;
      padding: 0;
      height: 100%;
      align-self: flex-start;
      top: 0;
      overflow: hidden;

      br {
        display: none;
      }

      [data-section="video-playback"],
      [data-section="instructions"] {
        display: none;
      }

      [data-section="greeting-card"] {
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        .gc__sub_heading {
          display: none;
        }
      }

      .gc__radio_buttons_container > .gc__radio_button_container > label.gc__radio_label {
        border: none;
      }
    }

    .gc__banner {
      display: none;
    }

  }

  @media screen and (max-width: 992px) {
    .gc__giftcard_preview {
      display: none;
    }
    .gc__hidden_desktop {
      display: none !important;
    }
  }

}

[data-theme="simple"].page-width {
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

[data-theme="simple"].gc__bulk-order-theme {
  #gcpRoot {
    flex: 1 0 50%;
    width: 50%;
  }

  #gcpBulkOrderLayoutSection {
    flex: 1 0 50%;
    margin: 0;
    border: none;
    .gc__button_container {
      display: none;
    }
  }
  #gcpBulkOrderTable {
    box-shadow: none;
    margin-top: 0;
    border: none;
    border-radius: 0;
  }
  #gcpBulkOrderActions {
    padding-right: 15px;
  }

  .gc__giftcard_preview {
    display: none;
  }
}

.gc__bulk-order-theme[data-theme="simple"] #gcpPurchaseFormWrapper .gc__giftcard_page .gc__footer-section {
  display: none;
}
