#gc__thumbnail_canvas {}

/*
  Styles used to make the radio inputs for the thumbnail selector
*/
.gc__thumbnail_radio_label {
  max-width: 250px;
  display: flex;
  border: solid 5px transparent;
  margin: 0 5px;
  cursor: pointer;
}

.gc__thumbnail_radio_image {
  max-width: 240px;
}

.gc__thumbnail_input {
  display: none;
}

.gc__thumbnail_radio_button {}

.gc__hidden_canvas {
  display: none;
}

.gc__thumbnail_input:checked + .gc__thumbnail_radio_label {
  border: 5px solid white;
  border-radius: 5px;
}
