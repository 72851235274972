#gc__video_upload {
  display: none;
}

.gc__label_button {
  display: flex;
  margin: 0;
  outline: 0;
  padding: 20px;
  width: 100%;
  background: transparent;
  border: 1px dashed #8f8f8f;
  color: #404040;
  font-size: 16px;
  font-weight: bold;
  line-height: 1em;
  text-transform: capitalize;
  border-radius: 0.3em;
}

.gc__label_button:hover {
  cursor: pointer;
  background: #f9f9f9;
}

.gc__label_button:active {
  box-shadow: inset 2px 2px 3px 0px #00000021, inset 0px 0px 5px -1px rgba(0, 0, 0, 0.01);
}

.gc__label_button .gc__icon {
  height: 2em;
}

.gc__label_button_image_container {
  width: 55px;
  margin: 0.4em 0;
  max-width: 100%;
}

.gc__label_button_text {
  flex: 1;
  font-size: 16px;
  font-family: inherit;
  max-width: 100%;
  display: flex;
  align-items: center;
  height: 2em;
  margin: 0.4em 0;
}
