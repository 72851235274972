#gcpVideoRecordingModal {

  .gc__modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  .gc__modal_container {
    background-color: #fff;
    padding: 0;
    width: auto;
    // max-width: 800px;
    // max-height: 650px;
    border-radius: 0;
    box-sizing: border-box;
  }

  .gc__modal_content {
    position: inherit;
    color: rgba(0,0,0,.8);
  }

  .gc__modal-1-content {
    pointer-events: auto;
  }

}
