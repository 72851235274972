.gc__giftcard-radio-image {
  width: 100%;
}

.gc__giftcard_image > img {
  width: 100%;
}

.gc__absolute-icon {
  position: absolute;
  top: 5px;
  left: 7px;
  z-index: 1;
}
.gc__icon {
  display: block;
  height: 15px;
  width: 15px;
  margin: auto;
  color: #fff;
}

.gc__icon svg {
  fill: var(--gc__var-brand_color);
}

.gc__image_radio_button_container {
  padding: 0;
  width: 100%;
  border-radius: 0px;
  position: relative;
}

@media all and (-ms-high-contrast:none) {
  .gc__radio_buttons_container {
    font-size: 0;
  }
  .gc__image_radio_button_container {
    padding: 0;
    width: 50%;
  }
  *::-ms-backdrop .gc__radio_buttons_container {
    font-size: 0;
  }
  *::-ms-backdrop .gc__image_radio_button_container {
    padding: 0;
    width: 50%;
  }

}

.gc__image_radio_label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border: 2px solid transparent;
  border-radius: var(--gc__var-radio_button_border_radius);
}

.gc__image_radio_input {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  visibility: hidden;
}

.gc__custom_value_label {
  margin-bottom: 0;
}
/* .gc__image_radio_input:checked:after {
  content: "✓";
  display: block;
  line-height: 100%;
  visibility: visible;
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: left;
  padding: 10px;
  color: #cfcfcf;
  background: #00a1f624;
  vertical-align: middle;
  top: 0;
  left: 0;
  border: 2px solid var(--gc__var-accent_color);
} */



.cover {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 8px;
}

@media screen and (max-width: 700px) {
	.cover {
		display: block;
	}
}

.atvImg {
	border-radius: 8px;
	transform-style: preserve-3d;
	-webkit-tap-highlight-color: rgba(#000,0);
}

.atvImg img {
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(14,21,47,0.25);
}

.gc__image_radio_input:checked + .atvImg  {

}

.gc__image_radio_input:checked + .atvImg .atvImg-container {
  transform: scale(1.05);
  outline: 2px solid var(--gc__var-accent_color);
  box-shadow: 0 2px 10px rgba(14,21,47,0.2);
}

.atvImg-container {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	transition: all 0.2s ease-out;
}

.atvImg-container.over .atvImg-shadow {
	box-shadow: 0 45px 100px rgba(14,21,47,0.1), 0 16px 40px rgba(14,21,47,0.1);
}

.atvImg-layers {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	overflow: hidden;
	transform-style: preserve-3d;
}

.atvImg-rendered-layer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0%;
	left: 0%;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	background-size: cover;
	transition: all 0.1s ease-out;
	overflow: hidden;
	border-radius: 8px;
}

.atvImg-shadow {
	position: absolute;
	top: 5%;
	left: 5%;
	width: 90%;
	height: 90%;
	transition: all 0.2s ease-out;
	box-shadow: 0 8px 30px rgba(14,21,47,0.15);
}

.atvImg-shine {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 8px;
	background: linear-gradient(135deg, rgba(255,255,255,.25) 0%,rgba(255,255,255,0) 10%);
}
