/*
  The container for the permissions error and the thumbnail select container
*/
.gc__overlay_middle_error_container, .gc__thumbnail_select_container {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* top: 0; */
  /* z-index: 1; */
  /* width: 100%; */
  height: 100%;
  background-color: black;
  color: white;
}

.gc__permissions_error_message, .gc__thumbnail_message {
  text-align: center;
  max-width: 400px;
}

.gc__permissions_error_header, .gc__thumbnail_header {
  color: white;
}

/*
  Styles used for the loading spinner
*/

.gc__camera_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*
  Styles used for the record screen message
*/

.gc__record_button_message_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 350px;
  color: white;
}

.gc__record_button_message {
  text-align: center;
  max-width: 400px;
  font-weight: bold;
}

/*
  Styles used for the retake and use video buttons container
*/

.gc__overlay_middle_playback_container {
  /* position: absolute; */
  /* top: 0; */
  /* width: 100%; */
  color: white;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.gc__playback_button_group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gc__playback_button_title {
  font-weight: bold;
  /* text-shadow: 1px 1px #000000; */
}

/*
  Styles used for the thumbnail images wrapper
*/

.gc__thumbnail_images_wrapper {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 450px) {
  .gc__thumbnail_images_wrapper {
    display: flex;
    justify-content: flex-start;
    overflow: scroll;
    margin: 0 10px;
  }

  .gc__permissions_error_message, .gc__thumbnail_message {
    text-align: center;
    max-width: 350px;
  }
}
