.gcp__card-details {
    padding-left: 18px;
    box-sizing: border-box
}
.gcp__card-details li {
  font-size: 11px;
  font-style: italic;
  list-style: outside;
  padding: 0;
  margin: 0;
}
.gcp__card-details li .link {
  font-size: 11px;
}
[data-gcp-trans="global.bundle_contents"] {
  font-size: 12px;
}
