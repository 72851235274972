[data-theme="product_template"] {

  margin-bottom: 15px;

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
    max-width: 100%;
    display: block;
    min-width: 0;
  }
  .gc__input_container > .gc__input_field {
    flex: 1;
    position: relative;
    padding: 0 1.3em;
  }
  .PhoneInputInput {
    flex: 1;
    min-width: 0;
    height: 100%;
    border: none;
  }
  .gc__radio_buttons_container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .gc__delivery_picker_div .gc__radio_buttons_container {
    grid-template-columns: 1fr 1fr;
    column-gap: 0rem;
    row-gap: 0rem;
  }
  .gc__step_active {
    min-height: 0px;
  }
  .gc__giftcard_page .gc__greeting-card-section.gc__section {
    min-height: 0px;
  }
  .gc__step_inactive {
    pointer-events: auto;
    display: block;
    min-height: 0px;
    position: relative;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-animation: fadeInFromNone 0.5s ease-out;
    -moz-animation: fadeInFromNone 0.5s ease-out;
    -o-animation: fadeInFromNone 0.5s ease-out;
    animation: fadeInFromNone 0.5s ease-out;
  }
  .gc__tab_content {
    width: 0px;
    position: relative;
    display: none;
  }
  .gc__tab_content.gc__tab_content_active {
    width: 100%;
    display: block;
  }
  .gc__giftcard_selection_div {
    min-height: 0px;
  }

  .intl-tel-input {
    max-width: 100%;
    flex: 1;
    display: flex;
    box-sizing: border-box;
    align-self: stretch;
    margin: 0.4em 0;
  }
  .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0;
    width: 100%;
    -webkit-box-shadow:  5px 5px 10px -5px rgba(100, 93, 93, 0.1);
    box-shadow:  5px 5px 10px -5px rgba(100, 93, 93, 0.1);
    height: auto;
    border: 1px solid #f5f2f2;
    border-radius: 0.3em;
    border-top-left-radius: 0;
  }
  .intl-tel-input.allow-dropdown input:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--gc__var-accent_color);
  }
  .iti-flag {
    display: block !important;
  }

}
