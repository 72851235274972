/*
  Styles used to create the video playback progress bar
*/
.gc__progress_bar_container {
  text-shadow: 1px 1px 0px black;
  color: white;
  width: 100%;
  padding: 15px;
  position: absolute;
  top: 295px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.gc__progress_bar {
  background-color: rgba(255,255,255,0.2);
  width: 90%;
  left: 0;
  top: 4px;
  height: 10px;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
}

.gc__progress_bar_progress {
  background-color: #fff;
  left: 0;
  top: 4px;
  width: 0;
  height: 10px;
  z-index: 2;
}

.gc__progress_bar_time {
    font-style: inherit;
    font-size: inherit;
}
