/* Loading spinner styling */

.gc__loading_spinner_div {
  display: flex;
  justify-content: center;
}

.gc__loading_spinner {
  border: 3px solid #ffffff;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 3px solid #555;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
