.gc__banner {
  padding: 15px;
  border: 1px solid;
  border-radius: 0.3em;
  margin: 0 0 10px;
  white-space: pre-wrap;
  line-height: 1.3em;
  ul {
    margin: 0;
    padding: 0 0 0 1em;
    li {

    }
  }
}

.gc__banner h1,
.gc__banner h2,
.gc__banner h3,
.gc__banner h4,
.gc__banner h5,
.gc__banner h6 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1em;
}

.gc__banner p {
  margin: 5px 0;
  font-size: 15px;
  padding: 0;
  line-height: 1em;
}

.gc__banner p:last-of-type {
  margin: 5px 0 0 0;
  padding: 0;
}

.gc__banner.gs__class-banner_state_notice {
  background: #f6f6f6;
  border-color: #ecebeb;
  color: #17343e;
  border-left: 15px solid #ecebeb;
  color: #b1b1b1;
}

.gc__banner.gs__class-banner_state_error {
  background: rgba(255, 244, 244, 1);
  border-color: rgba(224, 179, 178, 1);
  border-left: 15px solid rgba(224, 179, 178, 1);
  color: #a10000;
  max-width: 100%;
}

.gc__banner.gs__class-banner_state_warning {
  background: rgb(249, 245, 187);
  border-color: rgb(234, 229, 150);
  border-left: 15px solid rgb(234, 229, 150);
  color: #5f5700;
  max-width: 100%;
}

.gc__banner.gs__class-banner_state_info {
  background: rgb(230 241 250);
  border-color: rgb(208 225 238);
  border-left: 15px solid rgb(208 225 238);
  color: rgb(15, 45, 70);
  max-width: 100%;
}


.gc__banner {
  .gc__checkbox_container {
    font-size: 14px;
  }

  .gc__checkbox_checkmark {
    background-color: #fff;
    outline: 1px solid;
    outline-color: #ecebeb;
  }
  .gc__checkbox_container:hover .gc__checkbox_input ~ .gc__checkbox_checkmark {
    background-color: #eee;
  }
  .gc__checkbox_container .gc__checkbox_input:checked ~ .gc__checkbox_checkmark {
    background-color: #404040;
  }
  &.gs__class-banner_state_error .gc__checkbox_checkmark {
    outline-color: rgba(224, 179, 178, 1);
  }

  .gc__checkbox_container .gc__checkbox_input.gc__input_field_error:not(:checked) ~ .gc__checkbox_checkmark {
    background-color: #ffeeee;
    outline: 1px solid #a10000;
    border: none;
  }
}

.gc__physical-shipping-banner {
  margin-top: 5px;

  strong {
    color: #828282;
  }

  p {
    font-size: 14px;
  }

  .gc__checkbox_wrapper {
    color: #828282;
    padding-bottom: 0;
  }
}
