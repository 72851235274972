.gc__datetime_input {
  padding: 0.7em;
  border: 1px solid #ccc;
  border-radius: var(--gc__var-input_border_radius);
  font-size: 1.5rem;
  margin: 1rem 0;
  font-family: inherit;
  width: 400px;
  max-width: 100%;
}

.gc__datetime_input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.gc__datetime_input:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--gc__var-accent_color);
}

.gc__datetime_input:invalid {
  outline: none;
  box-shadow: none;
  /* background-color: #ffeeee;
  border: 1px solid #a10000; */
}

.gc__datetime_error_message {
  display: flex;
  margin: inherit;
  font-size: 0.85em;
  font-style: italic;
  color: #a10000;
  margin: 10px 0 0;
}

.gc__datetime_input_invalid {
  outline: none;
  box-shadow: none;
  background-color: #ffeeee;
  border: 1px solid #a10000;
}

.gc__datetime_input_container {
  display: flex;
}

.gc__timezone_input {
  padding: 0.7em;
  border: 1px solid #ccc;
  border-radius: var(--gc__var-input_border_radius);
  font-size: 1.5rem;
  margin: 1rem 0;
  max-width: 100%;
}

.gc__timezone_input:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--gc__var-accent_color);
}
