
.gc__progress-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 20px;
  background-color: gray;
  border-radius: 5px;
}

.gc__progress-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--gc__var-brand_color);
  border-radius: inherit;
}
