/* Input field styling */

.gc__input_container {
  display: flex;
  position: relative;
}

.gc__input_container > .gc__input_label {
  font-size: 1.6rem;
  width: 100px;
  padding: 1.3em;
  font-size: 1em;
  margin: 0.4em 0;
  border: 1px solid #f5f2f2;
  border-radius: 0.3em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-family: inherit;
  max-width: 100%;
  -webkit-box-shadow: 5px 5px 10px -5px rgb(100 93 93 / 10%);
  box-shadow: 5px 5px 10px -5px rgb(100 93 93 / 10%);
  border-right: 0;
  text-align: center;
  background: #f9f9f9;
}

.gc__input_container > .gc__input_field {
  flex: 1;
  position: relative;
  padding: 1.3em;
  font-size: 1em;
  margin: 0.4em 0;
  border: 1px solid #f5f2f2;
  border-radius: 0.3em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-family: inherit;
  max-width: 100%;
  -webkit-box-shadow: 5px 5px 10px -5px rgb(100 93 93 / 10%);
  box-shadow: 5px 5px 10px -5px rgb(100 93 93 / 10%);
  height: auto;
}

.gc__input_container > select.gc__input_field {
  width: 100%;
  padding-right: 0;
  height: auto;
}

.gc__custom_value_error {
  display: flex;
  margin: inherit;
  font-size: 0.85em;
  font-style: italic;
  color: #a10000;
  margin: 10px 0 0;
}

.gc__input_container > .gc__input_field:focus {
  outline: 2px var(--gc__var-accent_color);
  box-shadow: none;
  border-color: var(--gc__var-accent_color);
}

.gc__custom_value_empty_error {
  display: flex;
  margin: inherit;
  font-size: 0.85em;
  font-style: italic;
  color: #a10000;
  margin: 10px 0 0;
}

.gc__input_field_error {
  background-color: #ffeeee;
  border: 1px solid #a10000;
}

.gc__input_field_error:focus {}

.gc__input_field_error_message {
  margin: inherit;
  font-size: 0.85em;
  font-style: italic;
  color: #a10000;
}
