ul.gc__tab_navigation {
    margin: 0;
    text-align: left;
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}

ul.gc__tab_navigation > li.gc__tab_navigation_item  {
  display: inline-block;
  text-align: center;
  height: auto;
  line-height: 2em;
  padding: 0;
  margin: 0;
  width: 25%;
}

ul.gc__tab_navigation > li.gc__tab_navigation_item > a.gc__tab_navigation_link {
  text-decoration: none;
  color: #333;
  padding: 0;
  margin: 0;
  height: auto;
  width: 100%;
  display: block;
  border-radius: 0.3em;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  -webkit-transition: 0.2s;
  -webkit-transform-style: preserve-3d;
  -moz-transition:  0.2s;
  -moz-transform-style: preserve-3d;
  -o-transition:  0.2s;
  -o-transform-style: preserve-3d;
  transition: 0.2s;
  transform-style: preserve-3d;
}

ul.gc__tab_navigation > li.gc__tab_navigation_item.gc__active > a.gc__tab_navigation_link {
  background: var(--gc__var-accent_color) !important;
  color: #fff !important;;
  -webkit-box-shadow: 5px 5px 10px -5px rgba(100, 93, 93, 0.1);
  box-shadow: 5px 5px 10px -5px rgba(100, 93, 93, 0.1);
}
.gc__giftcard_selection_div {
  width: 100%;
  min-height: 500px;
}
.gc__tab_content_wrapper {
  width: 100%;
  position: relative;
  overflow: visible;
}
// .gc__giftcard_selection_div {
//   width: 999999999px;
// }
.gc__tab_content {
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;

  :empty {
    display: block
  }
}
.gc__tab_content.gc__tab_content_active {
  opacity: 1;
}
