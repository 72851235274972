/* Text area styling */

.gc__input_area {
  flex: 1;
  padding: 1.3em;
  font-size: 1em;
  margin: 0.4em 0;
  border: 1px solid #f5f2f2;
  border-radius: 0.3em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-family: inherit;
  max-width: 100%;
  -webkit-box-shadow: 5px 5px 10px -5px rgb(100 93 93 / 10%);
  box-shadow: 5px 5px 10px -5px rgb(100 93 93 / 10%);
}

.gc__input_area:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--gc__var-accent_color);
}
