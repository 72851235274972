#gcpBalancePage {

  .gc__balance_title {
    margin: 0;
    margin-bottom: 15px;
    font-size: 1.7em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > div {

    }

    > div:first-of-type {
      text-align: left;
    }

    > div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .gc__sub-heading {
      display: flex;
      align-items: center;
      font-weight: 700;
      margin: 0;
      color: var(--gc__var-brand_color);
      text-transform: uppercase;
      font-size: 0.5em;
      text-align: left;
    }

    .gc__giftcard-balance-icon {
      width: 25px;
      fill: var(--gc__var-brand_color);
    }

    .gc__success-icon {
      fill: #a5db86;
    }
  }

  .gc__shadow-box {
    background: linear-gradient(90deg, #f9f9f9 50%, #ffffff 50%);
    background-size: 100%;
    background-position: top left;
    background-repeat: no-repeat;

    > .gc__shadow-box {
      background: #fff;
    }
  }


}
