/*
  The Styles used for the Video Recorder
*/
#gc__video_stream {
  width: 100%;
  height: 100%;
}

.gc__overlay_wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.gc__video_recorder_container {
  /* max-height: 650px; */
  overflow: hidden;
  border-radius: 7px;
}

.gc__video_player {
  width: 800px;
  height: 450px;
  position: relative;
  background-color: black;
}

@media only screen and (max-width: 450px) {
  .gc__video_player {
    height: 650px;
    width: 375px;
    position: relative;
    background-color: black;
  }
}
