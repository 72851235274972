/*
  Styles used for the svg elements within the Video Recorder
*/
.gc__svg_large_buttons_container {
  display: flex;
}

.gc__svg_button_large_container_accept {
  /* background-color: #ccc; */
  display: flex;
  margin: 0 10px;
  border-radius: 50%;
  border: 5px solid #3ce669;
}

.gc__svg_button_large_container_playback {
  /* background-color: #ccc; */
  display: flex;
  margin: 0 10px;
  border-radius: 50%;
  border: 5px solid white;
}

.gc__svg_button_large_container_redo {
  /* background-color: #ccc; */
  display: flex;
  margin: 0 10px;
  border-radius: 50%;
  border: 5px solid #e03838;
}

.gc__svg_button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
}

.gc__svg_button_large {
  border: none;
  background: none;
  cursor: pointer;
  padding: 25px;
  background: #00000073;
  border-radius: 50%;
  max-width: 90px;
  max-height: 90px;
}

.gc__svg_button_text {
  font-size: inherit;
  font-family: inherit;
}

.gc__svg_filtered {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(116deg) brightness(80%) contrast(109%);
  height: 40px;
  width: 40px;
}

.gc__video_thumbnail:hover .gc__svg_filtered {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(116deg) brightness(109%) contrast(109%);
}
.gc__svg_redo {
  filter: invert(23%) sepia(77%) saturate(3858%) hue-rotate(348deg) brightness(98%) contrast(80%);
  height: 40px;
  width: 40px;
}

.gc__svg_accept {
  filter: invert(69%) sepia(59%) saturate(537%) hue-rotate(80deg) brightness(98%) contrast(93%);
  height: 40px;
  width: 40px;
}
