#gcpAddToCartModal {

  a {
    text-align: center;
    text-decoration: none;
  }
  .gc__swal2-html-container {
    font-size: 11px;
    font-weight: 300;
    color: #7b7b7b;
    text-align: justify;
    padding: 0 15px;
  }

  .gc__modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gc__add_to_cart_modal_content{
    background: white;
  }

  .gc_add_to_cart_modal_close_button {
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
  }

  .gc_add_to_cart_modal_close_button img{
    width: 5%;
  }

  .gc__add_to_cart_modal_notice{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .gc__swal2-icon {
    box-sizing: content-box;
  }

  .gc__add_to_cart_modal_image_container{
    margin: auto;
    width: 100%;
    height: auto;
  }

  .gc__add_to_cart_modal_notice img{
    width: 15%;
  }

  .gc__add_to_cart_modal_notice h2{

  }

  .gc__add_to_cart_modal_buttons{
    padding: 10px 30px;
    text-align: center;
  }

  .gc__add_to_cart_modal_button_group{
    display: flex;
    justify-content: space-around;
  }

  .gc__add_to_cart_modal_button_group button{
    outline: 0;
    margin: 0;
    padding: 20px;
    background: var(--gc__var-brand_color);
    // color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1em;
    text-transform: capitalize;
    border-width: 2px;
    border-style: none;
    border-image: none;
    border-radius: var(--gc__var-button_border_radius);
  }

}
