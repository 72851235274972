/*
  The styles used for the overlay top container
*/
.gc__overlay_top_container {
  position: absolute;
  top: 0;
  z-index: 2;
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
