.gc__message_checkboxes_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.gc__checkbox_wrapper {
  padding: 0.5em 1em 0.5em 0;
}

.gc__checkbox_container {
  position: relative;
  padding-left: 2em;
  cursor: pointer;
  line-height: 20px;
  vertical-align: top;
  font-size: 16px;
}

input.gc__checkbox_input {
  display: none;
}

.gc__checkbox_checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 0.3em;
}

.gc__checkbox_container:hover .gc__checkbox_input ~ .gc__checkbox_checkmark {
  background-color: var(--gc__var-accent_color);
}

.gc__checkbox_container .gc__checkbox_input:checked ~ .gc__checkbox_checkmark {
  background-color: var(--gc__var-brand_color);
}

.gc__checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.gc__checkbox_container .gc__checkbox_input:checked ~ .gc__checkbox_checkmark:after {
  display: block;
}

.gc__checkbox_container .gc__checkbox_checkmark:after {
  left: 7px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
