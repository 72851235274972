.gc__radio_buttons_container {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.gc__no_column_row_gap {
  column-gap: 0;
  row-gap: 0;
}


.gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input {
  display: none;
}


.gc__radio_buttons_container > .gc__radio_button_container label.gc__radio_label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  padding: 0;
  line-height: 50px;
  margin: 0;
  padding: 0 7px;
  border-bottom: 1px solid;
  border-top: 0px solid transparent;
  border-right: 1px solid;
  border-left: 1px solid;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: #585858;
  border-color: #f3f3f3;
  border-top-color: #f3f3f3 !important;
  border-left-color: #f3f3f3 !important;
  border-right-color: #f3f3f3 !important;
  border-bottom-color: #f3f3f3 !important;
}

.gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input:focus + label.gc__radio-label {
  outline: 2px var(--gc__var-accent_color);
  box-shadow: none;
  border-color: var(--gc__var-accent_color);
}

.gc__radio_buttons_container > .gc__radio_button_container:nth-child(2n) > label.gc__radio_label {
  border-left: 0px solid transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.gc__radio_buttons_container > .gc__radio_button_container:nth-child(1n) > label.gc__radio_label {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.gc__radio_buttons_container > .gc__radio_button_container:first-of-type > label.gc__radio_label {
  border-top: 1px solid;
  border-left: 1px solid;
  border-top-left-radius: 0.3em;
  border-top: 0px solid transparent;
}

.gc__radio_buttons_container > .gc__radio_button_container:nth-of-type(2)> label.gc__radio_label {
  border-top: 1px solid;
  border-top-right-radius: 0.3em;
}

/** Last radio button if it is on the left */
.gc__radio_buttons_container > .gc__radio_button_container:nth-last-child(1):nth-child(odd) {
  width: 100%;
  > label.gc__radio_label {
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
  }
}

/** Last radio button if it is on the right */
.gc__radio_buttons_container > .gc__radio_button_container:nth-last-child(1):nth-child(even) > label.gc__radio_label {
  border-bottom-right-radius: 0.3em;
}

/** First and second last element */
.gc__radio_buttons_container > .gc__radio_button_container:nth-last-child(2):nth-child(1) {
border-bottom-left-radius: 0.3em;
}


.gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input + label.gc__radio_label:hover {
  background: #f9f9f9;
}
.gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input:checked + label.gc__radio_label {
  background: #f9f9f9;
  box-shadow: inset 2px 2px 3px 0px #00000021, inset 0px 0px 5px -1px rgba(0, 0, 0, 0.01);
}
.gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input + label.gc__radio_label:active  {
  background: #f9f9f9;
  box-shadow: inset 2px 2px 3px 0px #00000021, inset 0px 0px 5px -1px rgba(0, 0, 0, 0.01);
}
.gc__radio_buttons_container > .gc__radio_button_container input.gc__radio_input:checked + label.gc__radio_label:hover  {
  background: #f9f9f9;
  box-shadow: inset 2px 2px 3px 0px #00000021, inset 0px 0px 5px -1px rgba(0, 0, 0, 0.01);
}

.gc__radio_buttons_container > .gc__radio_button_container .gc__radio-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.gc__mega-radio-container {
  display: grid;
  padding: 0;
  grid-template-columns: 1fr;
  column-gap: 1em;
  row-gap: 1em;
  flex-wrap: wrap;
  user-select: none;
  flex-direction: row;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .gc__mega-radio-button-wrapper {
    
    input {
      visibility: hidden;
      position: absolute;
      opacity: 0;
      left: -100px;
      top: 0;
      left: 0;
    }

    label {

      border: 1px solid #ecebeb;
      border-radius: 0.44em;
      -webkit-box-shadow: 5px 5px 24px -7px rgba(0, 0, 0, 0.1);
      box-shadow: 5px 5px 24px -7px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      cursor: pointer;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      background: #fff;
      position: relative;
      column-gap: 1em;
      padding: 1em;
      align-items: center;

      > span:first-child {
        width: 1em;
        flex-grow: 1;
        margin: 0 auto 0 0;
        display: flex;
        img {
          width: 100%;
          height: auto;
        }
      }

      > span {
        flex-grow: 2;
        > span:first-of-type {
          width: 100%;
          display: flex;
          align-items: center;
          font-weight: bold;
          margin: 0 0 0 0;
          color: var(--gc__var-brand_color);
          text-transform: uppercase;
          font-size: 14px;
          text-align: left;
        }

        > span:first-of-type + span  {
          width: 100%;
          font-size: 1em;
          color: #585858;
        }

      }
 

  
    }

    label:hover {
      background: #f9f9f9;
      transition: ease-in-out all 0.2s;
    }

    input:checked + label {
      background: #f9f9f9;
      box-shadow: inset 2px 2px 3px 0px #00000021, inset 0px 0px 5px -1px rgba(0, 0, 0, 0.01);
    }
  
  }

}


@media screen and (max-width: 992px) {
  .gc__mega-radio-container {
    padding: 3em;
    grid: repeat(auto-fill, minmax(calc(50% - 1em), 1fr));
  }
  
}

@media screen and (max-width: 768px) {
  .gc__mega-radio-container {
    padding: 2em;
  }
}

@media screen and (max-width: 500px) {
  .gc__mega-radio-container {
    padding: 0;
  }
}